'use strict';

Gri.module({
  name: 'map-beta',
  ieVersion: null,
  $el: $('.map-beta'),
  container: '.map-beta',
  fn: function () {
    
  }
});
